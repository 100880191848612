import { USPAttributes } from 'shared/types/product/USP';
import useSWR from 'swr';
import { sdk } from '~/sdk';

export function LoadUSP(
  usptype: string,
  successHandler: (a: USPAttributes) => JSX.Element,
  key?: string,
  errorHandler?: () => JSX.Element,
  loadingHandler?: () => JSX.Element,
) {
  const { data, error, isLoading } = useSWR('usp/' + usptype + '/' + key, () =>
    sdk
      .callAction<USPAttributes>({
        actionName: 'contentful-article/usp',
        query: { type: usptype, key: key ? key : '' },
      })
      .then((res) => res),
  );

  if (errorHandler && error) return errorHandler();
  if (loadingHandler && isLoading) return loadingHandler();

  if (!data || data.isError) {
    if (errorHandler) return errorHandler();
    else return null;
  } else {
    return successHandler(data.data);
  }
}

export default LoadUSP;
