import React, { HTMLAttributes } from 'react';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { USPAttributes } from 'shared/types/product/USP';
import RichTextContentful from '~/components/furniturechoice/rich-text/contentful';

export interface BaseUSPProps extends HTMLAttributes<HTMLDivElement> {
  data: USPAttributes;
}

export const uspOptions: Options = {
  renderNode: {
    // does not use <p> because <div> is not allowed to be a child element of <p>
    [BLOCKS.PARAGRAPH]: (_node, children) => <div className="leading-5">{children}</div>,
  },
};

interface WrapperUSPProps extends BaseUSPProps {
  children: JSX.Element | JSX.Element[];
}

export function USPWrapper({ data, children }: WrapperUSPProps): JSX.Element {
  return (
    <div
      id={'usp_content_' + data.type}
      className="h-full overflow-y-auto text-grey-5 scrollbar-hide"
      data-usp-type={data.type}
    >
      <h2 className="pb-2 text-20 text-grey-6">{data.title.toString()}</h2>
      {children}
    </div>
  );
}

export function USPContent({ data, ...props }: BaseUSPProps): JSX.Element {
  return (
    <USPWrapper data={data} {...props}>
      <div>
        <RichTextContentful data={data.text} options={uspOptions} />
      </div>
    </USPWrapper>
  );
}

export default USPContent;
