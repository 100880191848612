import React from 'react';
import { Options } from '@contentful/rich-text-react-renderer';
import RichTextContentful from '~/components/furniturechoice/rich-text/contentful';
import { USPWrapper, BaseUSPProps, uspOptions } from '../usp-content';

interface GuaranteeProps extends BaseUSPProps {
  duration: string;
}

export function processGuaranteeTitle(title: string, duration: string) {
  const guaranteeTitle = generateTemplateString(title);
  return guaranteeTitle({ year: duration.toLowerCase() });
}

export const generateTemplateString = (function () {
  function generateTemplate(template: string) {
    // Replace ${expressions} (etc) with ${map.expressions}.

    const sanitized = template
      .replace(/\$\{([\s]*[^;\s\{]+[\s]*)\}/g, function (_, match) {
        return `\$\{map.${match.trim()}\}`;
      })
      // Afterwards, replace anything that's not ${map.expressions}' (etc) with a blank string.
      .replace(/(\$\{(?!map\.)[^}]+\})/g, '');

    return Function('map', `return \`${sanitized}\``);
  }

  return generateTemplate;
})();

export default function ZeroFinance({ data, duration, ...props }: GuaranteeProps): JSX.Element {
  data.title = processGuaranteeTitle(data.title, duration.toLowerCase());

  const options: Options = {
    ...uspOptions,
    renderText: (text) => {
      const convert = generateTemplateString(text);
      const years_array: string[] = duration.toLowerCase().split('-');
      if (years_array[0] != '1') {
        years_array[1] = 'years';
      } else {
        years_array[1] = 'year';
      }
      const converted_text = convert({
        year: duration.toLowerCase(),
        years: years_array[0] + ' ' + years_array[1],
      });
      return converted_text;
    },
  };

  return (
    <USPWrapper data={data} {...props}>
      <div>
        <RichTextContentful data={data.text} options={options} />
      </div>
    </USPWrapper>
  );
}
