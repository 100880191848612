'use client';

import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { FNCLineItem } from 'shared/types/cart/LineItem';
import ReviewWidgetCart from '~/frontastic/tastics/furniturechoice/product/content-manager/extra-details/review-table/ReviewWidgetCart';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { getLineItemDisplayPrice } from '~/helpers/furniturechoice/displayPriceHelper';
import { useFormat } from '~/helpers/hooks/useFormat';
import ProductMainImage from '../../product-main-image';

interface LineItemProps {
  lineItem: FNCLineItem;
}

export default function LineItem({ lineItem }: LineItemProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { locale } = useParams();
  const isSwatch = lineItem.variant?.attributes?.['descriptors_reporting-group2'] == 'SWA';

  return (
    <div className="flex gap-x-4">
      <div
        className={clsx(
          'aspect-square h-fit min-w-[88px] max-w-[88px] overflow-hidden bg-grey-2 lg:min-w-[120px] lg:max-w-[120px]',
          {
            'rounded-full': isSwatch,
            'rounded-sm': !isSwatch,
          },
        )}
      >
        <ProductMainImage className="w-full object-contain" sku={lineItem.variant.sku} sizes="120px" />
      </div>

      <div className="flex w-full flex-col gap-y-4">
        <div className="flex flex-col gap-y-2">
          <p className="text-12 text-grey-4">
            {`${formatMessage({ id: 'components.checkout-order-summary.code' })}: ${lineItem.variant.sku}`}
          </p>

          <ReviewWidgetCart className="[&_img]:contrast-[.93]" product={lineItem} />
          <h3 className="font-sans font-bold leading-5">{lineItem.name}</h3>
        </div>

        <div className="flex items-center">
          <div className="surface-white flex min-w-[64px] items-center rounded-sm border border-solid border-grey-3 px-2 py-1">
            <span className="text-12 text-grey-5">
              {formatMessage({ id: 'components.checkout-order-summary.qty' })}
            </span>
            <span className="ml-auto text-14 font-bold">{lineItem.count}</span>
          </div>

          <p className="ml-auto font-bold text-grey-5">
            {CurrencyHelpers.formatForCurrency(getLineItemDisplayPrice(lineItem) ?? 0, locale)}
          </p>
        </div>
      </div>
    </div>
  );
}
