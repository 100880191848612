'use client';

import React, { useState, useEffect } from 'react';
import { MdChevronRight } from 'react-icons/md';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import { FetchUSP } from '~/components/furniturechoice/rich-text/contentful/helper/FetchUSP';
import SideModal from '~/components/furniturechoice/side-modal';
import { useCart } from '~/frontastic/hooks';
import { ImageProps } from '~/frontastic/lib/image';
import { useFormat } from '~/helpers/hooks/useFormat';
import { ContentfulUSPType } from '~/types/contentful/USP';
import { DataSourceV2 } from '~/types/datasource';

interface UspCoreProps {
  freeDeliveryImage?: ImageProps;
  freeReturnsImage?: ImageProps;
  financeImage?: ImageProps;
  financeProducts?: DataSourceV2<FinanceProduct[]>;
  price: number;
}

export default function UspCore({
  freeDeliveryImage,
  freeReturnsImage,
  financeImage,
  financeProducts,
}: UspCoreProps): JSX.Element {
  const cart = useCart();
  const [openFreeDelivery, setOpenFreeDel] = useState(false);
  const [openFreeReturns, setOpenFreeReturns] = useState(false);
  const [openZeroFinance, setOpenZeroFinance] = useState(false);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const [cartAmount, setCartAmount] = useState<number>(0);

  useEffect(() => {
    setCartAmount(cart.data && cart.data.sum && cart.data.sum.centAmount ? cart.data.sum.centAmount : 0);
  }, [cart]);

  const financeDataSource = {
    price: cartAmount,
    financeProducts: financeProducts,
    currencyCode: 'GBP',
  };
  const USPAttributes = [
    {
      title: 'Free delivery',
      imgSrc: freeDeliveryImage,
      alt: 'free_delivery',
      show: 'Free delivery',
      isOpen: openFreeDelivery,
      onClick: () => {
        setOpenFreeDel(!openFreeDelivery);
      },
      modal: <FetchUSP usptype={ContentfulUSPType.FREE_DELIVERY} uspkey="free-delivery-generic" />,
    },
    {
      title: 'Free returns',
      imgSrc: freeReturnsImage,
      alt: 'free_returns',
      subtitle: '30 days',
      show: 'Free returns',
      isOpen: openFreeReturns,
      onClick: () => {
        setOpenFreeReturns(!openFreeReturns);
      },
      modal: <FetchUSP usptype={ContentfulUSPType.FREE_RETURNS} />,
    },
    {
      title: '0% finance',
      imgSrc: financeImage,
      alt: 'zero_finance',
      show: '0% finance',
      isOpen: openZeroFinance,
      onClick: () => {
        setOpenZeroFinance(!openZeroFinance);
      },
      modal: <FetchUSP usptype={ContentfulUSPType.FINANCE} dataSource={financeDataSource} />,
    },
  ];

  return (
    <div className="relative my-6 flex flex-col justify-center border-grey-3 md:flex-row md:items-start md:space-x-2 md:border-y [&>button:first-child]:border-t [&>button]:border-b [&>button]:border-grey-3 md:[&>button]:border-none">
      {USPAttributes.map((attr) => (
        <button
          key={attr.alt}
          id={attr.alt}
          className="flex flex-1 items-center py-4 text-left md:relative md:block md:px-4 md:py-12 md:text-center"
          onClick={attr.onClick}
        >
          <CoFeImage asset={attr.imgSrc?.media} className="mr-2 w-[40px] md:mx-auto md:mb-2 md:w-[55px]"></CoFeImage>
          <div className="grow">
            <strong className="font-semibold">{attr.title}</strong>
            {attr.subtitle && <span className="ml-[5px] md:block">{attr.subtitle}</span>}
          </div>
          <MdChevronRight className="absolute right-0 w-[22px] fill-grey-7 text-20 md:hidden" />
          <div className="mt-4 hidden text-12 md:flex md:justify-center">
            <span>{formatMessage({ id: 'components.unique-selling-point.learn-more' })}</span>
            <MdChevronRight className="w-[15px] fill-grey-7" />
          </div>
        </button>
      ))}
      {USPAttributes.map((attr) => (
        <SideModal key={attr.alt} isOpen={attr.isOpen} onModalClose={attr.onClick}>
          {attr.modal}
        </SideModal>
      ))}
    </div>
  );
}
