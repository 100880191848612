import { HTMLAttributes } from 'react';
import { USPAttributes, USPCoreData } from 'shared/types/product/USP';
import LoadUSP from '~/helpers/furniturechoice/contentful/load-usp';
import { ContentfulUSPType } from '~/types/contentful/USP';
import { ShowBlankArticle } from '../page/inspiration-article';
import USP from '../page/usp';

interface FetchUSPProps extends HTMLAttributes<HTMLDivElement> {
  usptype: ContentfulUSPType;
  uspkey?: string;
  dataSource?: USPCoreData;
}

/**
 * Does an SWR AJAX call to load and display USP product from Contentful.
 * Also accepts HTMLDivElement props which will be applied to the top level div.
 *
 * @param {enum} usptype - The USP type, such as finance, free delivery, etc.
 * @param {string} uspkey - optional key to uniquely identify the USP from Contentful
 * @param {number} amount - optional amount in cents for finance calculator
 *
 * @returns {Object} Entire content of USP from Contenful fully processed into react elements. Returns a blank on error.
 */
export function FetchUSP({ usptype, uspkey, dataSource, ...props }: FetchUSPProps) {
  const successHandler = (value: USPAttributes) => (
    <USP type={usptype} data={value} dataSource={dataSource} {...props} />
  );
  const errorHandler = () => <></>;
  const loadingHandler = () => <ShowBlankArticle />;

  return LoadUSP(usptype, successHandler, uspkey, errorHandler, loadingHandler);
}
