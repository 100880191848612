import React from 'react';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import FinanceCalculator from '~/components/furniturechoice/finance-calculator';
import RichTextContentful from '~/components/furniturechoice/rich-text/contentful';
import { USPWrapper, BaseUSPProps, uspOptions } from '../usp-content';

interface ZeroFinanceProps extends BaseUSPProps {
  amount: number;
  currencyCode: string;
  financeTerms: FinanceProduct[];
}

export default function ZeroFinance({
  data,
  amount,
  currencyCode,
  financeTerms,
  ...props
}: ZeroFinanceProps): JSX.Element {
  return (
    <USPWrapper data={data} {...props}>
      <FinanceCalculator
        key={amount}
        amount={amount}
        financeProducts={financeTerms}
        currencyCode={currencyCode}
        configurableAmount={false}
      />
      <div>
        <RichTextContentful data={data.text} options={uspOptions} />
      </div>
    </USPWrapper>
  );
}
