import { HTMLAttributes } from 'react';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import { USPAttributes, USPCoreData, USPCoreFinanceData } from 'shared/types/product/USP';
import { ContentfulUSPType } from '~/types/contentful/USP';
import { DataSourceV2 } from '~/types/datasource';
import Guarantee from './guarantee';
import USPContent from './usp-content';
import ZeroFinance from './zero-finance';

interface USPProps extends HTMLAttributes<HTMLDivElement> {
  data: USPAttributes;
  type: ContentfulUSPType;
  dataSource?: USPCoreData;
}

/**
 * Please refer to Unique Selling Points in Content Model of Contentful for more information.
 * Used to display rich text USP content that appears in side modals.
 */
export default function USP({ data, type, dataSource, ...props }: USPProps) {
  switch (type) {
    case ContentfulUSPType.ABOUT_US:
    case ContentfulUSPType.FREE_RETURNS:
    case ContentfulUSPType.FREE_DELIVERY:
      return <USPContent data={data} {...props} />;
    case ContentfulUSPType.GUARANTEE:
      return <Guarantee data={data} duration={'1-YEAR'} {...props} />;
    case ContentfulUSPType.FINANCE:
      const financeDataSource = dataSource as unknown as USPCoreFinanceData;
      const financeTerms = financeDataSource.financeProducts as unknown as DataSourceV2<FinanceProduct[]>;

      if (financeTerms) {
        return (
          <ZeroFinance
            currencyCode={financeDataSource.currencyCode}
            financeTerms={financeTerms.dataSource}
            data={data}
            amount={financeDataSource.price || 100000}
            {...props}
          />
        );
      }
    default:
      return <USPContent data={data} {...props} />;
  }
}
