'use client';

import { HTMLAttributes } from 'react';
import { useParams } from 'next/navigation';
import { MdCheckCircle } from 'react-icons/md';
import { Cart } from 'shared/types/cart';
import CartTotal from '~/components/furniturechoice/cart-pricing/CartTotal';
import Link from '~/components/furniturechoice/link';
import UspCore from '~/components/furniturechoice/usp-core';
import { ImageProps } from '~/frontastic/lib/image';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { getPriceSummary } from '~/helpers/furniturechoice/displayPriceHelper';
import { useFormat } from '~/helpers/hooks/useFormat';
import LineItem from './line-item';

interface OrderSummaryProps extends HTMLAttributes<HTMLDivElement> {
  cart: Cart;
  freeDeliveryImage?: ImageProps;
  freeReturnsImage?: ImageProps;
  financeImage?: ImageProps;
}

export default function OrderSummary({
  cart,
  freeDeliveryImage,
  freeReturnsImage,
  financeImage,
  ...props
}: OrderSummaryProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { locale } = useParams();
  const { transaction, subtotalAmount, discountAmount } = getPriceSummary(cart);

  const subtotalCurrency = CurrencyHelpers.formatForCurrency(
    subtotalAmount,
    locale,
    transaction.subtotal.currencyCode,
    transaction.subtotal.fractionDigits,
  );
  const discountCurrency = CurrencyHelpers.formatForCurrency(
    discountAmount,
    locale,
    transaction.subtotal.currencyCode,
    transaction.subtotal.fractionDigits,
  );

  return (
    <section className="flex flex-col gap-y-5 lg:max-w-[471px]" {...props}>
      <header className="flex">
        <div className="flex gap-x-2">
          <h2 className="font-sans text-20 font-bold">
            {formatMessage({ id: 'components.checkout-order-summary.order_summary' })}
          </h2>

          <p className="mt-auto text-14 leading-none text-grey-7">
            {`(${cart.totalLineItemQuantity} ${formatMessage({
              id: 'components.checkout-order-summary.items',
            })})`}
          </p>
        </div>

        <Link className="ml-auto mt-auto text-12 leading-none text-grey-7" href="/basket">
          {`< ${formatMessage({ id: 'components.checkout-order-summary.amend' })}`}
        </Link>
      </header>
      <div className="surface-grey-1 flex flex-col gap-y-8 rounded-md px-4 pb-4 pt-8 lg:px-8">
        <div className="flex flex-col gap-y-4 lg:gap-y-8">
          {cart.lineItems?.map((lineItem) => (
            <LineItem key={lineItem.lineItemId} lineItem={lineItem} />
          ))}
        </div>

        <div className="border-t-2 border-solid border-white py-5">
          <table className="w-full">
            <thead className="sr-only">
              <tr>
                <th>{formatMessage({ id: 'components.checkout-order-summary.description' })}</th>
                <th>{formatMessage({ id: 'components.checkout-order-summary.price' })}</th>
              </tr>
            </thead>

            <tbody className="text-16 font-normal">
              <tr className="group text-grey-5">
                <td className="p-0.5 pr-4 group-[:nth-last-child(2)]:pb-5">
                  {formatMessage({ id: 'components.checkout-order-summary.order_subtotal' })}
                </td>

                <td className="p-0.5 text-right group-[:nth-last-child(2)]:pb-5">{subtotalCurrency}</td>
                {/* <td className="p-0 text-right">{CurrencyHelpers.formatForCurrency(cart.subtotal ?? 0, locale)}</td> */}
              </tr>

              {cart.shippingInfo?.price && (
                <tr className="group text-grey-5">
                  <td className="p-0.5 pr-4 group-[:nth-last-child(2)]:pb-5">
                    {formatMessage({ id: 'components.checkout-order-summary.delivery' })}
                  </td>

                  <td className="p-0.5 text-right group-[:nth-last-child(2)]:pb-5">
                    {CurrencyHelpers.formatForCurrency(cart.shippingInfo.price ?? 0, locale)}
                  </td>
                </tr>
              )}

              {discountAmount > 0 && (
                <tr className="group text-grey-5">
                  <td className="p-0.5 pr-4 text-green-2 group-[:nth-last-child(2)]:pb-5">
                    {formatMessage({ id: 'components.checkout-order-summary.discount_code_applied' })}{' '}
                    <MdCheckCircle className="inline" />
                  </td>

                  <td className="p-0.5 text-right group-[:nth-last-child(2)]:pb-5">-{discountCurrency}</td>
                </tr>
              )}

              <tr className="border-t-2 border-solid border-white">
                <td className="p-0 pr-4 pt-5 align-top text-20 font-bold">
                  {formatMessage({ id: 'components.checkout-order-summary.total' })}
                </td>

                <td className="p-0 pt-5 text-right">
                  <CartTotal className="text-20 font-bold" cart={cart} locale={locale} />
                  <div className="mt-2 text-14 text-grey-5">
                    {formatMessage({ id: 'components.checkout-order-summary.including_vat' })}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <UspCore
        freeDeliveryImage={freeDeliveryImage}
        freeReturnsImage={freeReturnsImage}
        financeImage={financeImage}
        price={cart.sum?.centAmount || 100000}
      />
    </section>
  );
}
