import { Address } from 'shared/types/account';

export default function isSameBillingAndShippingAddress(
  billingAddress: Address = {},
  shippingAddress: Address = {},
): boolean {
  const keys = [
    'salutation',
    'firstName',
    'lastName',
    'phone',
    'streetNumber',
    'streetName',
    'additionalAddressInfo',
    'city',
    'region',
    'postalCode',
    'country',
  ];
  const isAnyAddressFieldDifferent = keys.some((key) => {
    const addressKey = key as keyof Address;

    return billingAddress[addressKey] !== shippingAddress[addressKey];
  });

  return isAnyAddressFieldDifferent ? false : true;
}
